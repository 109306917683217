<template>
  <div>
    <div class="row">
      <div class="col">
        <!-- Featured List -->
        <featured-album-list :update="updateFeatured"
                             @update-album-table="updateAlbumTable"
                             @update-featured-table="updateFeaturedTable"
        ></featured-album-list>
      </div>

      <div class="col">
        <!-- Album List -->
        <album-list :update="updateAlbum"
                    @update-featured-table="updateFeaturedTable"
                    @update-album-table="updateAlbumTable"
        ></album-list>
      </div>
    </div>
    
    <!-- Dialog -->
    <v-dialog/>
  </div>
</template>

<script>
  const FeaturedAlbumList =
    () => import('@/components/admin/featured_album/FeaturedAlbumList.vue');
  const AlbumList =
    () => import('@/components/admin/featured_album/MobileAlbums.vue');
  export default {
    name : 'FeaturedAlbum',
    data() {
      return {
        updateAlbum    : false,
        updateFeatured : false,
      }
    },
    components : {
      AlbumList,
      FeaturedAlbumList,
    },
    methods : {
      updateFeaturedTable() {
        this.updateFeatured = (this.updateFeatured) ? false : true;
      },

      updateAlbumTable() {
        this.updateAlbum = (this.updateAlbum) ? false : true;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured_album/featured-album";
</style>